<script>
	import { onMount } from 'svelte';
	// Set Vars
	let timeNow = Number(Date.now());
	let timeStamp = Number(localStorage.getItem('timeStamp'));
	let complete = localStorage.getItem('complete') || false;
	let paused = localStorage.getItem('paused') || false;
	let name = 'Your Name';
	let message = 'Give Us Your Feedback';
	let step = 1;
	let display = 'block';

	
	onMount(() => {

		// Set Timestamp if NULL
		if(timeStamp === 0) {
			localStorage.setItem('timeStamp', timeNow);
			timeStamp = Number(localStorage.getItem('timeStamp'));
		}

		// Retrieve paused, set to boolean
		if(paused === 'true') {
			paused = true;
		} else if(paused === 'false') {
			paused = false;
		}

		// Retrieve complete, set to boolean
		if(complete === 'true') {
			complete = true;
		} else if(complete === 'false') {
			complete = false;
		} else {
			complete = false;
		}

		// Display Popup?
		if(complete === true || paused === true) {
			display = 'none';
		}

		// Unpause in 4 Days
		if(paused === true) {
			let difference = timeNow - timeStamp;
			console.log(difference);
			if(difference >= 345600000) {
				localStorage.setItem('timeStamp', timeNow);
				timeStamp = localStorage.getItem('timeStamp');
				localStorage.setItem('paused', false);
				paused = localStorage.getItem('paused');
			}
		}
	});
	// Pause Handler
	const handlePause = (e) => {
			localStorage.setItem('timeStamp', timeNow);
			timeStamp = localStorage.getItem('timeStamp');
			localStorage.setItem('paused', true);
			paused = localStorage.getItem('paused');
			display = 'none';
		}

		// Complete Handler
		const handleComplete = () => {
			localStorage.setItem('timeStamp', timeNow);
			timeStamp = localStorage.getItem('timeStamp');
			localStorage.setItem('complete', true);
			complete = localStorage.getItem('complete');
		}

		// Yes Handler
		const handleYes = (e) => {
			step = 2;
			handleComplete();
		}

		// Yes Handler
		const handleNo = (e) => {
			step = 3;
			handleComplete();
		}
		// Close Handler
		const handleClose = (e) => {
			display = 'none';
		}
		// Form Handler

		const handleForm = (e) => {
			e.preventDefault();
			let formData = "2689_fname=" + name + "&2693_message=" + message + "&website=548&form=400&function=process_form&from_url=" + window.location;

			let url = 'https://app.funnelscience.com/fry.php';
			fetch(url, {
				method: 'post',
				body: formData,
				headers: {
					"Content-Type": "application/x-www-form-urlencoded"
				}
			})
			.then(() => {
				step = 4;
			})
			.catch((err) => console.log(err))
		}
</script>

<div class="survey" style="--display: {display}">
	<div class="survey-wrap">
		{#if step === 2}
			<h3>Please Leave Us A Review!</h3>
			<p><a href="http://bit.ly/googlereviewsfunnelscience" target="_blank">Google Review</a></p>
			<p><a href="https://reviews.capterra.com/new/170271" target="_blank">Capterra Review</a></p>
			<p><button on:click|preventDefault={handleClose}>Done</button></p>
		{:else if step === 3}
			<h3>Tell Us Why</h3>
			<form on:submit={handleForm}>
				<input type="text" bind:value={name} name="name" id="name">
				<textarea bind:value={message} name="message" id="message"></textarea>
				<button type="submit">Submit Feedback</button>
			</form>
			
		{:else if step === 4}
			<h3>Thank You</h3>
			<p>Thank you for your feedback!</p>
			<p><button on:click={handleClose}>Close</button></p>
		{:else}
			<h3>Are You Enjoying Funnel Science?</h3>
			<p><button on:click={handleYes}>Yes</button> <button on:click={handleNo}>No</button> <button on:click={handlePause}>Answer Later</button></p>
	{/if}
	</div>
</div>

<style>
	.survey {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 16px;
		background-color: #f0f0f0;
		color: #333;
		display: var(--display);
		z-index: 50;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 400px;
		border: 3px solid #727cf5;
	}
	.survey-wrap {
		margin: 20px;
	}
	.survey-wrap p, .survey-wrap h3 {
		text-align: center;
	}
	.survey-wrap textarea {
		width: 100%;
		min-height: 400px;
	}
</style>